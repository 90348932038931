import { post, get, burn, put } from './request'

export default {
  create: (payload, success, failure) => post('/v1/checklist-template', payload).then(success).catch(failure),
  update: (payload, success, failure) => post(`/v1/checklist-templates/${payload.uuid}`, payload).then(success).catch(failure),
  load: (checklistId, success, failure) => get(`/v1/checklist-templates/${checklistId}`).then(success).catch(failure),
  loadOverview: (checklistId, success, failure) => get(`/v1/checklist-templates/${checklistId}/overview`).then(success).catch(failure),
  loadList: (search, success, failure) => get(`/v1/checklist-template-list`, success, failure, {
    params: {
      search
    }
  }),
  searchForAvailableCheckpoints: (payload, success, failure) => get(`/v1/checklist-templates/${payload.checklist_id}/available-checkpoints`, success, failure, {
    params: {
      search: payload.search
    }
  }),
  addCheckpoint: (payload, success, failure) => post(`/v1/checklist-templates/${payload.checklist_id}/checkpoint`, payload).then(success).catch(failure),
  removeCheckpoint: (payload, success, failure) => burn(`/v1/checklist-templates/${payload.checklist_id}/checkpoint/${payload.checkpoint_id}`, payload).then(success).catch(failure),
  updateCheckpointSort: (payload, success, failure) => put(`/v1/checklist-templates/${payload.checklist_id}/sort`, payload).then(success).catch(failure),
  copy: (payload, success, failure) => post(`/v1/checklist-templates/${payload}/copy`).then(success).catch(failure),
  deleteChecklist: (payload, success, failure) => burn(`/v1/checklist-templates/${payload}`).then(success).catch(failure),
}