import { post, get, put, burn } from './request'

export default {
    create: (payload, success, failure) => post(`/v1/work-order-template`, payload).then(success).catch(failure),
    save: (payload, success, failure) => put(`/v1/work-order-templates/${payload.id}`, payload).then(success).catch(failure),
    pause: (id, success, failure) => put(`/v1/work-order-templates/${id}/pause`).then(success).catch(failure),
    resume: (payload, success, failure) => put(`/v1/work-order-templates/${payload.id}/resume`, payload).then(success).catch(failure),
    load: (templateId, success, failure) => get(`/v1/work-order-templates/${templateId}`).then(success).catch(failure),
    loadList: (success, failure) => get(`/v1/work-order-template-list`).then(success).catch(failure),
    loadNextSchedule: (templateId, success, failure) => get(`/v1/work-order-templates/${templateId}/next-schedule`).then(success).catch(failure),
    deleteTemplate: (templateId, success, failure) => burn(`/v1/work-order-templates/${templateId}`).then(success).catch(failure),
    loadOverview: (templateId, success, failure) => get(`/v1/work-order-templates/${templateId}/overview`).then(success).catch(failure),
    skipSchedule: (payload, success, failure) => put(`/v1/work-order-templates/${payload.workOrderId}/schedule/${payload.scheduleId}/skip`).then(success).catch(failure),
    updateScheduleDate: (payload, success, failure) => put(`/v1/work-order-templates/${payload.work_order_id}/schedule/${payload.schedule_id}/date`, payload).then(success).catch(failure),
}