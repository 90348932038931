import { post, get, burn } from './request'

export default {
  generateNewCardCheckout: (payload, success, failure) => post('/v1/account/card-checkout', payload).then(success).catch(failure),
  verifyNewCard: (payload, success, failure) => post('/v1/account/verify-payment', payload).then(success).catch(failure),
  loadSubscription: (success, failure) => get('/v1/subscription').then(success).catch(failure),
  loadPlans: (success, failure) => get('/v1/plans').then(success).catch(failure),
  startTrial : (payload, success, failure) => post('/v1/start-trial', payload).then(success).catch(failure),
  activateAndBillForSubscription : (payload, success, failure) => post('/v1/activate-subscription', payload).then(success).catch(failure),
  cancelAccount : (success, failure) => post('/v1/account/cancel', {}).then(success).catch(failure),
  loadCard: (success, failure) => get('/v1/card').then(success).catch(failure),
  deleteCard: (success, failure) => burn('/v1/card').then(success).catch(failure),
  downgradeToFree: (success, failure) => post('/v1/downgrade-to-free').then(success).catch(failure),
  newCardCheckout: (success, failure) => post('/v1/new-card').then(success).catch(failure),
  downgradeToLowerTier: (priceId, success, failure) => post('/v1/subscription/downgrade', {
    price_id: priceId
  }).then(success).catch(failure),
  upgradePlan: (priceId, success, failure) => post('/v1/subscription/upgrade', {
    price_id: priceId
  }).then(success).catch(failure),
  payInvoice: (invoiceId, success, failure) => post(`/v1/invoices/${invoiceId}/pay`).then(success).catch(failure),
}