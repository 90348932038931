import Branch from '../models/Branch'
import { branch as backend } from '@/api'

export default {

  namespaced: true,

  state: {
    branch: new Branch,
    branch_list: [],
    overview: {
      total_work_orders: 0,
      work_orders_this_month: 0,
      work_orders_last_month: 0,
      total_checklists_this_month: 0,
      total_checklists_last_month: 0,
      total_checklists: 0,
      total_staff: 0,
      total_teams: 0,
      recent_work_orders: [],
    }
  },

  mutations: {
    ...Branch.mutations(),
    setList: (state, payload) => state.branch_list = payload,
    setOverview: (state, payload) => state.overview = payload,
    addBranch: (state, payload) => state.branch_list.push(payload)
  },

  actions: {
    create: ({state}) => new Promise((resolve, reject) => {
      const branch = {...state.branch}
      branch.users = branch.users.map(user => user.uuid)
      backend.create(branch, ({data}) => {
        resolve(data)
      }, reject)
    }),
    update: ({state}) => new Promise((resolve, reject) => {
      const branch = {...state.branch}
      branch.users = branch.users.map(user => user.uuid)
      backend.update(branch, resolve, reject)
    }),
    loadList: ({commit}) => new Promise((resolve, reject) => {
      backend.loadList(({data}) => {
        commit('setList', data)
        resolve()
      }, reject)
    }),
    loadBranch: ({commit}, branchId) => new Promise((resolve, reject) => {
      backend.loadBranch(branchId, ({data}) => {
        commit('setBranch', data)
        resolve()
      }, reject)
    }),
    loadOverview: ({commit}, branchId) => new Promise((resolve, reject) => {
      backend.loadOverview(branchId, ({data}) => {
        commit('setOverview', data)
        resolve()
      }, reject)
    })
  },

  getters: {
    branch: state => state.branch,
    branch_list: state => state.branch_list,
    overview: state => state.overview,
  }

}