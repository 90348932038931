export default class Customer {

  constructor(customer = {}) {
    this.id = customer.id || null
    this.uuid = customer.uuid || ''
    this.reference = customer.reference || ''
    this.name = customer.name || null
    this.branches = customer.branches || []
    this.is_business = customer.is_business || true
    this.location = customer.location || {
      type: '',
      name: '',
      address: {},
      branches: []
    }
  }

  static mutations() {
    return {
      setCustomer: (state, payload) => state.customer = new Customer(payload),
      clearCustomer: (state) => state.customer = new Customer(),
      updateName: (state, payload) => state.customer.name = payload,
      updateReference: (state, payload) => state.customer.reference = payload,
      updateBranches: (state, payload) => state.customer.branches = payload,
      toggleIsBusiness: (state) => state.customer.is_business = !state.customer.is_business,
      updateLocationType: (state, payload) => state.customer.location.type = payload,
      updateLocationName: (state, payload) => state.customer.location.name = payload,
      updateLocationBranches: (state, payload) => state.customer.location.branches = payload,
      updateAddress: (state, payload) => {
        state.customer.location.address = payload

        state.customer.location.address.display_text = [
          state.customer.location.address.street,
          state.customer.location.address.suburb,
          state.customer.location.address.city,
          state.customer.location.address.country,
        ].reduce((string, item) => `${string}${item}, `, '')

        state.customer.location.address.display_text = state.customer.location.address.display_text.substring(0, state.customer.location.address.display_text.length - 2)
      }
    }
  }
}