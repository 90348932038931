import _auth from './auth'
import _billing from './billing'
import _support from './support'
import _common from './common'
import _branch from './branch'
import _team from './team'
import _user from './user'
import _customer from './customer'
import _location from './location'
import _workOrderClassification from './work-order-classification'
import _checklist from './checklist'
import _checkpoint from './checkpoint'
import _workOrder from './work-order'
import _priority from './priority'
import _inspection from './inspection'
import _classification from './classification'
import _equipmentClassification from './equipment-classification'
import _checklistClassification from './checklist-classification'
import _country from './country'
import _equipment from './equipment'
import _permissions from './permissions'
import _alert from './alert'
import _report from './report'
import _workOrderTemplate from './work-order-template'
import _attachment from './attachment'
import _industry from './industry'
import _area from './area'

export const auth = _auth
export const billing = _billing
export const support = _support
export const common = _common
export const branch = _branch
export const team = _team
export const user = _user
export const customer = _customer
export const location = _location
export const workOrderClassification = _workOrderClassification
export const checklist = _checklist
export const checkpoint = _checkpoint
export const workOrder = _workOrder
export const priority = _priority
export const inspection = _inspection
export const classification = _classification
export const equipmentClassification = _equipmentClassification
export const checklistClassification = _checklistClassification
export const country = _country
export const equipment = _equipment
export const permissions = _permissions
export const alert = _alert
export const report = _report
export const workOrderTemplate = _workOrderTemplate
export const attachment = _attachment
export const industry = _industry
export const area = _area