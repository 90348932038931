import { post, get } from './request'
import { services, utilities } from '@billow/js-helpers'

export default {
  signin: (payload, success, failure) => post('/v1/authenticate', services.auth.passportLoginPayload(payload)).then(success).catch(failure),
  signup: (payload, success, failure) => post('/v1/signup', payload).then(success).catch(failure),
  forgotPassword: (payload, success, failure) => post('/v1/forgot-password', payload).then(success).catch(failure),
  resetPassword: (payload, success, failure) => post('/v1/reset-password', payload).then(success).catch(failure),
  loadAuthUser: (success, failure) => get('/v1/user').then(success).catch(failure),
  loadAccount: (success, failure) => get('/v1/account').then(success).catch(failure),
  loadAccountAddress: (success, failure) => get('/v1/account/address').then(success).catch(failure),
  updateProfile: (payload, success, failure) => post('/v1/profile', payload).then(success).catch(failure),
  updateAccount: (payload, success, failure) => post('/v1/account', payload).then(success).catch(failure),
  updateEmail: (payload, success, failure) => post('/v1/email', payload).then(success).catch(failure),
  updatePassword: (payload, success, failure) => post('/v1/change-password', payload).then(success).catch(failure),
  createUser: (payload, success, failure) => post('/v1/user', payload).then(success).catch(failure),
  uploadProfilePicture: (payload, success, failure) => post('/v1/profile/picture', utilities.formData(payload)).then(success).catch(failure),
  uploadEmailLogo: (payload, success, failure) => post('/v1/account/email-logo', utilities.formData(payload)).then(success).catch(failure),
  uploadReportLogo: (payload, success, failure) => post('/v1/account/report-logo', utilities.formData(payload)).then(success).catch(failure),
  setupAccount: (payload, success, failure) => post('/v1/account/setup', payload).then(success).catch(failure),
  loadUnreadNotificationsCount: (success, failure) => get('/v1/unread-notifications').then(success).catch(failure),
}