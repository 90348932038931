export default class Ticket
{
  constructor(ticket = {}) {
    this.id = ticket.id || null
    this.ticket_type_id = ticket.ticket_type_id || ''
    this.number = ticket.number || ''
    this.message = ticket.message || ''
    this.attachment = ticket.attachment || null
    this.type = ticket.type || {}
    this.user = ticket.user || {}
    this.closed_at = ticket.closed_at || ''
    this.replies = ticket.replies || []
    this.attachments = ticket.attachments || []
  }

  static mutations() {
    return {
      updateType: (state, payload) => state.ticket.ticket_type_id = payload,
      updateMessage: (state, payload) => state.ticket.message = payload,
      updateAttachment: (state, payload) => state.ticket.attachment = payload,
      clearTicket: (state) => state.ticket = new Ticket(),
      setTicket: (state, payload) => state.ticket = new Ticket(payload),
      addReply: (state, payload) => state.ticket.replies.push(payload),
    }
  }
}