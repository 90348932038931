export default class Address {

    constructor(address = {}) {
        this.id = address.id || null
        this.street = address.street || ''
        this.city = address.city || ''
        this.suburb = address.suburb || ''
        this.province = address.province || ''
        this.post_code = address.post_code || ''
        this.timezone = address.timezone || ''
        this.lat = address.lat || ''
        this.lng = address.lng || ''
        this.country_id = address.country_id || ''
        this.country = address.country || {}
        this.addressable_id = address.addressable_id || ''
        this.addressable_type = address.addressable_type || ''
        this.display_text = address.display_text || ''
    }

    static mutations() {
        return {
            updateStreet: (state, payload) => state.address.street = payload,
            updateCity: (state, payload) => state.address.city = payload,
            updateSuburb: (state, payload) => state.address.suburb = payload,
            updatePostCode: (state, payload) => state.address.post_code = payload,
            updateTimezone: (state, payload) => state.address.timezone = payload,
            updateLat: (state, payload) => state.address.lat = payload,
            updateLng: (state, payload) => state.address.lng = payload,
            updateCountry: (state, payload) => state.address.country_id = payload,
        }
    }

}