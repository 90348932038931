import { get, post } from './request'
import { utilities } from '@billow/js-helpers'

export default {
  paginatedTickets: (payload, success, failure) => get('/v1' + payload.path, { params: payload.query }).then(success).catch(failure),
  loadTicketTypes: (success, failure) => get('/v1/ticket-types').then(success).catch(failure),
  loadTicket: (ticketId, success, failure) => get(`/v1/ticket/${ticketId}`).then(success).catch(failure),
  openTicket: (payload, success, failure) => post('/v1/ticket', utilities.formData(payload)).then(success).catch(failure),
  closeTicket: (ticketId, success, failure) => post(`/v1/ticket/${ticketId}/close`, {}).then(success).catch(failure),
  addReply: (payload, success, failure) => post(`/v1/ticket/${payload.ticket_id}/reply`, utilities.formData(payload)).then(success).catch(failure),
}