export default {

  namespaced: true,
  
  state: {
    errors: {
      errors: {},
      message: ''
    },
    frontend_version: ''
  },

  mutations: {
    setErrors: (state, payload) => state.errors = payload,
    clearErrors: (state) => state.errors = { errors: {}, message: '' },
    setFrontendVersion: (state, payload) => state.frontend_version = payload,
  },

  getters: {
    errors: state => state.errors,
    current_version: state => state.frontend_version,
  }

}