import Team from '../models/Team'
import { team as backend } from '@/api'

export default {

  namespaced: true,

  state: {
    team: new Team,
    team_list: [],
    overview: {
      total_work_orders: 0,
      failed_work_orders: 0,
      failed_checklists_last_month: 0,
      failed_checklists_this_month: 0,
      passed_checklists_last_month: 0,
      passed_work_orders: 0,
      in_progress_work_orders: 0,
      overdue_work_orders: 0,
      total_checklists: 0,
      failed_checklists: 0,
      passed_checklists: 0,
      overdue_checklists: 0,
      team_members: 0,
      recent_checklists: [],
      total_work_order_runtime: 0,
      average_work_order_runtime: 0,
    }
  },

  mutations: {
    ...Team.mutations(),
    setTeamList: (state, payload) => state.team_list = payload,
    setOverview: (state, payload) => state.overview = payload,
  },

  actions: {
    create: ({ state }) => new Promise((resolve, reject) => {
      const team = {...state.team}
      team.users = team.users.map(user => user.id)
      backend.create(team, resolve, reject)
    }),
    update: ({ state }) => new Promise((resolve, reject) => {
      const team = {...state.team}
      team.users = team.users.map(user => user.id)
      backend.update(team, resolve, reject)
    }),
    loadTeam: ({ commit }, teamId) => new Promise((resolve, reject) => {
      backend.loadTeam(teamId, ({data}) => {
        commit('setTeam', data)
        resolve(data)
      }, reject)
    }),
    loadTeamList: ({ commit }) => new Promise((resolve, reject) => {
      backend.loadTeamList({}, ({data}) => {
        commit('setTeamList', data)
        resolve()
      }, reject)
    }),
    removeTeamMember: ({ state, commit }, user) => new Promise((resolve, reject) => {
      backend.removeTeamMember({
        team_id: state.team.uuid,
        user_id: user.id
      }, () => {
        commit('removeTeamMember', user)
        resolve()
      }, reject)
    }),
    addTeamMember: ({ state, commit }, user) => new Promise((resolve, reject) => {
      commit('addTeamMember', user)
      backend.addTeamMember({
        team_id: state.team.uuid,
        user_id: user.id
      }, resolve, reject)
    }),
    loadOverview: ({ commit }, teamId) => new Promise((resolve, reject) => {
      backend.loadOverview(teamId, ({data}) => {
        commit('setOverview', data)
        resolve()
      }, reject)
    }),
    deleteTeam: (_, teamId) => new Promise((resolve, reject) => {
      return backend.deleteTeam(teamId, resolve, reject)
    })
  },

  getters: {
    team: state => state.team,
    team_list: state => state.team_list,
    overview: state => state.overview,
  }

}