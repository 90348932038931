import { billing as backend } from '@/api'
import Subscription from '../models/Subscription'
import moment from 'moment'
import sortBy from 'lodash/sortBy'

export default {

  namespaced: true,

  state: {
    subscription: new Subscription(),
    previous_trials: 0,
    usage: {
      users: 0
    },
    plans: [],
    subscription_checkout: {
      plan_price_id: '',
      annual: false
    }
  },

  mutations: {
    ...Subscription.mutations(),
    setUsage: (state, payload) => state.usage = payload,
    setPlans: (state, payload) => state.plans = payload,
    setSubscriptionCheckout: (state, payload) => state.subscription_checkout = payload,
    setPreviousTrials: (state, payload) => state.previous_trials = payload
  },

  actions: {
    generateNewCardCheckout: (_, payload) => new Promise((resolve, reject) => {
      backend.generateNewCardCheckout(payload, ({data}) => resolve(data), reject)
    }),
    verifyNewCard: (_, payload) => new Promise((resolve, reject) => {
      backend.verifyNewCard(payload, ({data}) => resolve(data), reject)
    }),
    loadSubscription: ({commit}) => new Promise((resolve, reject) => {
      return backend.loadSubscription(({data}) => {
        commit('setSubscription', data.subscription)
        commit('setUsage', data.usage)
        commit('setPreviousTrials', data.previous_trials)
        commit('auth/setAccount', data.subscription.account, { root: true })
        resolve()
      }, reject)
    }),
    loadPlans: ({commit}) => new Promise((resolve, reject) => {
      backend.loadPlans(({data}) => {
        commit('setPlans', sortBy(data, 'sort'))
        resolve()
      }, reject)
    }),
    startTrial: (_, payload) => new Promise((resolve, reject) => {
      backend.startTrial(payload, resolve, reject)
    }),
    activateAndBillForSubscription: ({state}, payload) => new Promise((resolve, reject) => {
      backend.activateAndBillForSubscription({
        ...state.subscription_checkout,
        ...payload
      }, resolve, reject)
    }),
    cancelAccount: () => new Promise((resolve, reject) => {
      backend.cancelAccount(resolve, reject)
    }),
    deleteCard: () => new Promise((resolve, reject) => {
      backend.deleteCard(resolve, reject)
    }),
    downgradeToFree: () => new Promise((resolve, reject) => {
      backend.downgradeToFree(resolve, reject)
    }),
    downgradeToLowerTier: (_, priceId) => new Promise((resolve, reject) => {
      backend.downgradeToLowerTier(priceId, resolve, reject)
    }),
    upgradePlan: (_, priceId) => new Promise((resolve, reject) => {
      backend.upgradePlan(priceId, resolve, reject)
    }),
    newCardCheckout: () => new Promise((resolve, reject) => {
      backend.newCardCheckout(resolve, reject)
    })
  },

  getters: {
    subscription: state => state.subscription,
    subscriptionLimits: state => state.subscription.price.plan.limits,
    onTrial: state => state.subscription.on_trial,
    daysLeftInTrial: state => {
      if (state.subscription.trials_expires_at) {
        const now = moment()
        const trial_expiry = moment(state.subscription.trials_expires_at)
        return now().diff(trial_expiry, 'days')
      }

      return null
    },
    usage: state => state.usage,
    plans: state => state.plans,
    subscription_checkout: state => state.subscription_checkout,
    previous_trials: state => state.previous_trials,
  }

}