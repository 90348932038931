import Customer from '../models/Customer'
import { customer as backend } from '@/api'

export default {

  namespaced: true,

  state: {
    customer: new Customer(),
    location_types: [],
    customer_list: [],
    overview: {
      total_equipment: 0,
      faulty_equipment: 0,
      work_orders_in_progress: 0,
      total_working_staff: 0,
      recent_checklists: [],
      total_man_hours: '',
      checklists_graph: [],
      checklists: [],
    },
  },

  mutations: {
    ...Customer.mutations(),
    setLocationTypes: (state, payload) => state.location_types = payload,
    setCustomerList: (state, payload) => state.customer_list = payload,
    setOverview: (state, payload) => state.overview = payload,
  },

  actions: {
    loadLocationTypes: ({commit}) => new Promise((resolve, reject) => {
      backend.loadLocationTypes(({data}) => {
        commit('setLocationTypes', data)
        resolve()
      }, reject)
    }),
    loadCustomerList: ({commit}) => new Promise((resolve, reject) => {
      backend.loadCustomerList(({data}) => {
        commit('setCustomerList', data)
        resolve()
      }, reject)
    }),
    create: ({state}, location) => new Promise((resolve, reject) => {
      const _customer = {...state.customer}
      _customer.location = location
      _customer.location.branches = _customer.location.branches.map(branch => branch.id)
      backend.create(_customer, resolve, reject)
    }),
    update: ({state}) => new Promise((resolve, reject) => {
      backend.update(state.customer, resolve, reject)
    }),
    deleteCustomer: ({state}) => new Promise((resolve, reject) => {
      backend.deleteCustomer(state.customer.uuid, resolve, reject)
    }),
    load: ({commit}, customerId) => new Promise((resolve, reject) => {
      backend.load(customerId, ({data}) => {
        commit('setCustomer', data)
        resolve()
      }, reject)
    }),
    loadOverview: ({commit}, customerId) => new Promise((resolve, reject) => {
      backend.loadOverview(customerId, ({data}) => {
        commit('setOverview', data)
        resolve()
      }, reject)
    })
  },

  getters: {
    customer: state => state.customer,
    location_types: state => state.location_types,
    customer_list: state => state.customer_list,
    overview: state => state.overview,
  }

}