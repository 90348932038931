import { post, get } from './request'

export default {
  create: (payload, success, failure) => post('/v1/checkpoint', payload).then(success).catch(failure),
  update: (payload, success, failure) => post(`/v1/checkpoints/${payload.uuid}`, payload).then(success).catch(failure),
  load: (checkpointId, success, failure) => get(`/v1/checkpoints/${checkpointId}`).then(success).catch(failure),
  loadTypes: (success, failure) => get(`/v1/checkpoint-types`).then(success).catch(failure),
  searchForCheckpoints: (payload, success, failure) => get(`/v1/checkpoints/search`, success, failure, {
    params: payload
  }),
}