import { http } from './request'

export default {
  downloadFile: (path, success, failure, params = {}) => {
    return http({
      method: 'get',
      url: path,
      responseType: 'blob',
      params,
    }
    ).then(response => success(response))
      .catch(error => failure(error))
  }    
}