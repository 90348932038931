import { get, put, post, burn } from './request'
import { utilities } from '@billow/js-helpers'

export default {
  load: (locationId, success, failure) => get(`/v1/locations/${locationId}`).then(success).catch(failure),
  update: (payload, success, failure) => put(`/v1/locations/${payload.uuid}`, payload).then(success).catch(failure),
  create: (payload, success, failure) => post(`/v1/locations`, payload).then(success).catch(failure),
  loadOverview: (customerId, success, failure) => get(`/v1/locations/${customerId}/overview`).then(success).catch(failure),
  uploadEquipmentImport: (payload, success, failure) => post(`/v1/locations/${payload.uuid}/import-equipment`, utilities.formData(payload)).then(success).catch(failure),
  createContact: (payload, success, failure) => post(`/v1/locations/${payload.uuid}/contacts`, payload).then(success).catch(failure),
  loadContact: (payload, success, failure) => get(`/v1/locations/${payload.location}/contacts/${payload.contact}`).then(success).catch(failure),
  updateContact: (payload, success, failure) => put(`/v1/locations/${payload.contactable_uuid}/contacts/${payload.uuid}`, payload).then(success).catch(failure),
  createArea: (payload, success, failure) => post(`/v1/locations/${payload.uuid}/areas`, payload).then(success).catch(failure),
  transferWorkOrders: (payload, success, failure) => post(`/v1/locations/${payload.from_location_uuid}/transfer-work-orders`, payload).then(success).catch(failure),
  deleteLocation: (payload, success, failure) => burn(`/v1/locations/${payload}`).then(success).catch(failure),
}