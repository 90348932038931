import { post, get, burn, put } from './request'

export default {
  create: (payload, success, failure) => post('/v1/team', payload).then(success).catch(failure),
  update: (payload, success, failure) => post(`/v1/teams/${payload.uuid}`, payload).then(success).catch(failure),
  deleteTeam: (payload, success, failure) => burn(`/v1/teams/${payload}`, payload).then(success).catch(failure),
  loadTeam: (teamId, success, failure) => get(`/v1/teams/${teamId}`).then(success).catch(failure),
  loadTeamList: (params, success, failure) => {
    get(`/v1/team-list`, success, failure, {
      params: params
    })
  },
  loadOverview: (teamId, success, failure) => get(`/v1/teams/${teamId}/overview`).then(success).catch(failure),
  removeTeamMember: (payload, success, failure) => burn(`/v1/teams/${payload.team_id}/member/${payload.user_id}`).then(success).catch(failure),
  addTeamMember: (payload, success, failure) => put(`/v1/teams/${payload.team_id}/member/${payload.user_id}`).then(success).catch(failure),
}