import { post, get, put, burn } from './request'

export default {
  create: (payload, success, failure) => post(`/v1/work-order`, payload).then(success).catch(failure),
  save: (payload, success, failure) => put(`/v1/work-order/${payload.uuid}`, payload).then(success).catch(failure),
  load: (workOrderId, success, failure) => get(`/v1/work-orders/${workOrderId}`).then(success).catch(failure),
  delete: (workOrderId, success, failure) => burn(`/v1/work-order/${workOrderId}`).then(success).catch(failure),
  markAsComplete: (workOrderId, success, failure) => post(`/v1/work-order/${workOrderId}/complete`).then(success).catch(failure),
  reopen: (workOrderId, success, failure) => post(`/v1/work-order/${workOrderId}/reopen`).then(success).catch(failure),
  loadInspections: (workOrderId, success, failure) => get(`/v1/work-orders/${workOrderId}/inspections`).then(success).catch(failure),
}