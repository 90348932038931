export default class Account {
  constructor(account = {}) {
    this.id = account.id || null
    this.is_setup = account.is_setup || null
    this.company_name = account.company_name || ''
    this.registration_number = account.registration_number || ''
    this.vat_number = account.vat_number || ''
    this.cancelled_at = account.cancelled_at || ''
    this.email_logo = account.email_logo || ''
    this.industry_id = account.industry_id || ''
    this.report_logo = account.report_logo || ''
    this.card = account.card || {}
    this.address = account.address || {
      lat: '',
      lng: '',
      street: '',
      suburb: '',
      city: '',
      province: '',
      country: '',
      post_code: '',
      display_text: ''
    }
  }

  static accountMutations() {
    return {
      setAccount: (state, payload) => state.account = new Account(payload),
      updateCompanyName: (state, payload) => state.account.company_name = payload,
      updateRegistrationNumber: (state, payload) => state.account.registration_number = payload,
      updateVatNumber: (state, payload) => state.account.vat_number = payload,
      updateEmailLogo: (state, payload) => state.account.email_logo = payload,
      updateReportLogo: (state, payload) => state.account.report_logo = payload,
      updateIndustry: (state, payload) => state.account.industry_id = payload,
      setAddress: (state, payload) => state.account.address = payload,
      clearCard: (state) => state.account.card = {},
      updateAddress: (state, payload) => {
        state.account.address = payload

        state.account.address.display_text = [
          state.account.address.street,
          state.account.address.suburb,
          state.account.address.city,
          state.account.address.country,
        ].reduce((string, item) => `${string}${item}, `, '')

        state.account.address.display_text = state.account.address.display_text.substring(0, state.account.address.display_text.length -2)
      }
    }
  }
}  