import { post, get, put } from './request'

export default {
  loadList: (success, failure) => get('/v1/user-list').then(success).catch(failure),
  loadUser: (userId, success, failure) => get(`/v1/user/${userId}`).then(success).catch(failure),
  loadOverview: (userId, success, failure) => get(`/v1/users/${userId}/overview`).then(success).catch(failure),
  createUser: (payload, success, failure) => post('/v1/user', payload).then(success).catch(failure),
  updateUser: (payload, success, failure) => post(`/v1/users/${payload.uuid}`, payload).then(success).catch(failure),
  grantAdminAccess: (payload, success, failure) => post(`/v1/users/${payload}/grant-admin`).then(success).catch(failure),
  revokeAdminAccess: (payload, success, failure) => post(`/v1/users/${payload}/revoke-admin`).then(success).catch(failure),
  resetPassword: (payload, success, failure) => post(`/v1/users/${payload.uuid}/reset-password`, payload).then(success).catch(failure),
  changeEmailAddress: (payload, success, failure) => put(`/v1/users/${payload.uuid}/email`, payload).then(success).catch(failure),
}