export default class Subscription
{
  constructor(subscription = {}) {
    this.next_invoice = subscription.next_invoice || null
    this.account_id = subscription.account_id || null
    this.account = subscription.account || {}
    this.on_trial = subscription.on_trial || false
    this.trial_expires_at = subscription.trial_expires_at || ''
    this.price = subscription.price || {
      plan: {
        limits: {}
      }
    }
  }

  static mutations() {
    return {
      setSubscription: (state, payload) => state.subscription = new Subscription(payload)
    }
  }
}