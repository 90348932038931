import Branch from './Branch'

export default class Team {

  constructor(team = {}) {
    this.id = team.id || null
    this.uuid = team.uuid || ''
    this.name = team.name || null
    this.branch_id = team.branch_id || ''
    this.branch_uuid = team.branch_uuid || ''
    this.team_lead_id = team.team_lead_id || ''
    this.team_lead_uuid = team.team_lead_uuid || ''
    this.leader = team.leader || {}
    this.users = team.users || []
    this.branch = team.branch || new Branch()
    this.work_orders_count = team.work_orders_count || 0
  }

  static mutations() {
    return {
      setTeam: (state, payload) => state.team = new Team(payload),
      clearTeam: (state) => state.team = new Team(),
      updateName: (state, payload) => state.team.name = payload,
      updateBranch: (state, payload) => state.team.branch_uuid = payload,
      updateMembers: (state, payload) => state.team.users = payload,
      clearMembers: (state) => state.team.users = [],
      updateTeamLead: (state, payload) => state.team.team_lead_uuid = payload,
      updateTeamLeadId: (state, payload) => state.team.team_lead_id = payload,
      removeTeamMember: (state, user) => {
        const index = state.team.users.indexOf(user)
        state.team.users.splice(index, 1)
      },
      addTeamMember: (state, user) => {
        state.team.users.push(user)
      },
    }
  }
}