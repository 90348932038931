import User from '../models/User'
import Account from '../models/Account'
import { auth as backend } from '@/api'
import { services } from '@billow/js-helpers'

export default {

  namespaced: true,

  state: {
    user: new User,
    account: new Account
  },

  mutations: {
    ...User.authMutations(),
    ...User.commonMutations(),
    ...Account.accountMutations(),
  },

  actions: {
    signin: ({state, dispatch}) => new Promise((resolve, reject) => {
      backend.signin({
        username: state.user.email,
        password: state.user.password
      }, async ({data}) => {
        services.auth.setToken(data.access_token)
        await dispatch('loadAuthUser').then(async () => {
          await dispatch('billing/loadSubscription', null, {root: true})
          resolve()
        }).catch(reject)
      }, reject)
    }),
    signup: ({state}) => new Promise((resolve, reject) => {
      backend.signup({
        name: state.user.name,
        surname: state.user.surname,
        email: state.user.email,
        password: state.user.password,
        password_confirmation: state.user.password_confirmation,
      }, resolve, reject)
    }),
    loadAuthUser: ({commit}) => new Promise((resolve, reject) => {
      return backend.loadAuthUser(({data}) => {
        commit('setUser', data)
        resolve()
      }, () => {
        commit('deleteToken')
        reject()
      })
    }),
    loadAccount: ({commit}) => new Promise((resolve, reject) => {
      backend.loadAccount(({data}) => {
        commit('setAccount', data)
        resolve()
      }, reject)
    }),
    loadAccountAddress: ({commit}) => new Promise((resolve, reject) => {
      backend.loadAccountAddress(({data}) => {
        commit('setAddress', data)
        resolve(data)
      }, reject)
    }),
    updateProfile: ({state}) => new Promise((resolve, reject) => {
      backend.updateProfile({
        name: state.user.name,
        surname: state.user.surname,
        mobile: state.user.profile.mobile
      }, resolve, reject)
    }),
    updateAccount: ({state}, address) => new Promise((resolve, reject) => {
      backend.updateAccount({
        company_name: state.account.company_name,
        registration_number: state.account.registration_number,
        vat_number: state.account.vat_number,
        industry_id: state.account.industry_id,
        address: address,
      }, resolve, reject)
    }),
    updateEmail: ({ commit }, payload) => new Promise((resolve, reject) => {
      backend.updateEmail(payload, () => {
        commit('updateEmail', payload.email)
        resolve()
      }, reject)
    }),
    updatePassword: (_, payload) => new Promise((resolve, reject) => {
      backend.updatePassword(payload, resolve, reject)
    }),
    createUser: ({ state }) => new Promise((resolve, reject) => {
      backend.createUser({
        name: state.user.name,
        surname: state.user.surname,
        email: state.user.email,
        mobile: state.user.profile.mobile
      }, resolve, reject)
    }),
    uploadProfilePicture: ({ commit }, file) => new Promise((resolve, reject) => {
      backend.uploadProfilePicture({ file }, ({data}) => {
        commit('updateProfilePicturePath', data)
      }, reject)
    }),
  },

  getters: {
    user: state => state.user,
    account: state => state.account,
    account_is_setup: state => state.account.is_setup,
  }

}