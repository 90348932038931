import { support as backend } from '@/api'
import Ticket from '../models/Ticket'

export default {

  namespaced: true,

  state: {
    ticket_types: [],
    ticket: new Ticket
  },

  mutations: {
    setTicketTypes: (state, payload) => state.ticket_types = payload,
    ...Ticket.mutations()
  },

  actions: {
    loadTicketTypes: ({commit}) => new Promise((resolve, reject) => {
      backend.loadTicketTypes(({data}) => {
        commit('setTicketTypes', data)
        resolve()
      }, reject)
    }),
    openTicket: ({state}) => new Promise((resolve, reject) => {
      backend.openTicket(state.ticket, resolve, reject)
    }),
    loadTicket: ({commit}, ticketId) => new Promise((resolve, reject) => {
      backend.loadTicket(ticketId, ({data}) => {
        commit('setTicket', data)
        resolve()
      }, reject)
    }),
    closeTicket: (_, ticketId) => new Promise((resolve, reject) => {
      backend.closeTicket(ticketId, resolve, reject)
    }),
    addReply: ({state, commit}, reply) => new Promise((resolve, reject) => {
      backend.addReply({
        ticket_id: state.ticket.id,
        message: reply.message,
        attachment: reply.attachment,
      }, ({data}) => {
        commit('addReply', data)
        resolve()
      }, reject)
    })
  },

  getters: {
    ticketTypes: state => state.ticket_types,
    ticket: state => state.ticket,
  }

}