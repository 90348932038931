import { post, get, put, burn } from './request'

export default {
    create: (payload, success, failure) => post('/v1/reports', payload).then(success).catch(failure),
    update: (payload, success, failure) => put(`/v1/reports/${payload.id}`, payload).then(success).catch(failure),
    load: (payload, success, failure) => get(`/v1/reports/${payload}`).then(success).catch(failure),
    loadReportData: (payload, success, failure) => get(
        `/v1/reports/${payload.report_id}/${payload.type}`, 
        success, 
        failure, {
            params: {
                start_date: payload.start_date
            }
        }
    ),
    createWidget: (payload, success, failure) => post(`/v1/reports/${payload.report_id}/widgets`, payload).then(success).catch(failure),
    loadWidget: (payload, success, failure) => get(`/v1/report-widgets/${payload}`).then(success).catch(failure),
    updateWidget: (payload, success, failure) => put(`/v1/report-widgets/${payload.id}`, payload).then(success).catch(failure),
    deleteWidget: (payload, success, failure) => burn(`/v1/report-widgets/${payload}`).then(success).catch(failure),
}