import { post, get, put, burn } from './request'

export default {
  create: (payload, success, failure) => post('/v1/customer', payload).then(success).catch(failure),
  update: (payload, success, failure) => put(`/v1/customers/${payload.uuid}`, payload).then(success).catch(failure),
  deleteCustomer: (uuid, success, failure) => post(`/v1/customers/${uuid}/delete`).then(success).catch(failure),
  loadLocationTypes: (success, failure) => get('/v1/location-types-list').then(success).catch(failure),
  loadCustomerList: (success, failure) => get('/v1/customer-list').then(success).catch(failure),
  loadCustomerLocationsList: (uuid, success, failure) => get(`/v1/customers/${uuid}/locations-list`).then(success).catch(failure),
  load: (customerId, success, failure) => get(`/v1/customers/${customerId}`).then(success).catch(failure),
  loadOverview: (customerId, success, failure) => get(`/v1/customers/${customerId}/overview`).then(success).catch(failure),
  createContact: (payload, success, failure) => post(`/v1/customers/${payload.uuid}/contact`, payload).then(success).catch(failure),
  updateContact: (payload, success, failure) => put(`/v1/customers/${payload.customer_uuid}/contact/${payload.uuid}`, payload).then(success).catch(failure),
  loadContact: (payload, success, failure) => get(`/v1/customers/${payload.customer_uuid}/contact/${payload.contact_uuid}`).then(success).catch(failure),
  deleteContact: (payload, success, failure) => burn(`/v1/customers/${payload.customer_uuid}/contact/${payload.uuid}`).then(success).catch(failure),
}