import { services } from '@billow/js-helpers'
import { findIndex } from 'lodash'

export default class User
{
  constructor(user = {}) {
    this.id = user.id || null
    this.uuid = user.uuid || ''
    this.name = user.name || ''
    this.surname = user.surname || ''
    this.full_name = user.full_name || ''
    this.email = user.email || ''
    this.password = user.password || ''
    this.password_confirmation = user.password_confirmation || ''
    this.profile = user.profile || {
      picture_url: '',
      mobile: '',
      notification_preferences: {
        checklist_completed: false,
        work_order_completed: false,
        work_order_opened: false,
        recurring_work_order_skipped: false,
      }
    }
    this.active = user.active || false
    this.is_passive = user.is_passive || false
    this.automatic_teams = user.automatic_teams || true
    this.branches = user.branches || []
    this.teams = user.teams || []
    this.customers = user.passive_customers || []
    this.locations = user.passive_locations || []
    this.abilities = user.abilities || []
    this.designation = user.designation || ''
  }

  static authMutations() {
    return {
      updateEmail: (state, payload) => state.user.email = payload,
      updatePassword: (state, payload) => state.user.password = payload,
      updatePasswordConfirmation: (state, payload) => state.user.password_confirmation = payload,
      toggleActive: (state) => state.user.active = !state.user.active,
      togglePassive: (state) => state.user.is_passive = !state.user.is_passive,
      deleteToken: (state) => {
        state.user = new User()
        services.auth.destroyToken()
      },
    }
  }
  
  static commonMutations() {
    return {
      setUser: (state, payload) => state.user = new User(payload),
      clearUser: (state) => state.user = new User(),
      updateName: (state, payload) => state.user.name = payload,
      updateSurname: (state, payload) => state.user.surname = payload,
      updateMobile: (state, payload) => state.user.profile.mobile = payload,
      updateBranches: (state, payload) => state.user.branches = payload,
      updateCustomers: (state, payload) => state.user.customers = payload,
      updateLocations: (state, payload) => state.user.locations = payload,
      updateDesignation: (state, payload) => state.user.designation = payload,
      updateTeams: (state, payload) => state.user.teams = payload,
      addAbility: (state, payload) => state.user.abilities.push(payload),
      setAbilities: (state, payload) => state.user.abilities = payload,
      toggleAutomaticTeams: (state) => state.user.automatic_teams = !state.user.automatic_teams,
      togglePermission: (state, permission) => {
        const index = findIndex(state.user.abilities, ability => ability.id === permission.id)
        if(index === -1) {
          return state.user.abilities.push(permission)
        }
        state.user.abilities.splice(index, 1)
      },
      updateProfilePicturePath: (state, payload) => state.user.profile.picture_url = payload,
      toggleChecklistCompletionNotification: (state) => state.user.profile.notification_preferences.checklist_completed = !state.user.profile.notification_preferences.checklist_completed,
      toggleWorkOrderCompletionNotification: (state) => state.user.profile.notification_preferences.work_order_completed = !state.user.profile.notification_preferences.work_order_completed,
      toggleWorkOrderOpenedNotification: (state) => state.user.profile.notification_preferences.work_order_opened = !state.user.profile.notification_preferences.work_order_opened,
      toggleWorkOrderSkippedNotification: (state) => state.user.profile.notification_preferences.recurring_work_order_skipped = !state.user.profile.notification_preferences.recurring_work_order_skipped,
    }
  }
}