import { user as backend } from '@/api'
import User from '../models/User'

export default {

  namespaced: true,

  state: {
    user: new User,
    user_list: [],
    overview: {
      total_work_orders: 0,
      total_checklists: 0,
      in_progress_work_orders: 0,
      in_progress_checklists: 0,
      failed_checklists: 0,
      passed_checklists: 0,
      average_checklist_time: 0,
      total_checklist_time: 0,
      recent_checklists: [],
      total_teams: 0,
      total_teams_lead: 0,
      devices: []
    }
  },

  mutations: {
    ...User.authMutations(),
    ...User.commonMutations(),
    setList: (state, payload) => state.user_list = payload,
    setOverview: (state, payload) => state.overview = payload,
  },

  actions: {
    createUser: ({ state }) => new Promise((resolve, reject) => {
      const user = {...state.user}
      user.branches = user.branches.map(branch => branch.id)
      user.teams = user.teams.map(team => team.id)
      user.abilities = user.abilities.map(ability => ability.id)
      user.customers = user.customers.map(customer => customer.id)
      user.locations = user.locations.map(location => location.id)
      backend.createUser(user, ({data}) => {
        resolve(data)
      }, reject)
    }),
    loadUser: ({ commit }, userId) => new Promise((resolve, reject) => {
      backend.loadUser(userId, ({data}) => {
        commit('setUser', data)
        resolve()
      }, reject)
    }),
    loadOverview: ({ commit }, userId) => new Promise((resolve, reject) => {
      backend.loadOverview(userId, ({data}) => {
        commit('setOverview', data)
        resolve()
      }, reject)
    }),
    updateUser: ({ state }) => new Promise((resolve, reject) => {
      const user = { ...state.user }
      user.branches = user.branches.map(branch => branch.id)
      user.teams = user.teams.map(team => team.id)
      user.abilities = user.abilities.map(ability => ability.id)
      user.customers = user.customers.map(customer => customer.id)
      user.locations = user.locations.map(location => location.id)
      backend.updateUser(user, resolve, reject)
    }),
    loadList: ({ commit }) => new Promise((resolve, reject) => {
      backend.loadList(({data}) => {
        commit('setList', data)
        resolve()
      }, reject)
    }),
    grantAdminAccess: ({ state, commit }) => new Promise((resolve, reject) => {
      backend.grantAdminAccess(state.user.uuid, ({data}) => {
        commit('setAbilities', data)
        resolve()
      }, reject)
    }),
    revokeAdminAccess: ({ state, commit }) => new Promise((resolve, reject) => {
      backend.revokeAdminAccess(state.user.uuid, ({data}) => {
        commit('setAbilities', data)
        resolve()
      }, reject)
    }),
    resetPassword: ({ state }, payload) => new Promise((resolve, reject) => {
      backend.resetPassword({
        uuid: state.user.uuid,
        ...payload
      }, resolve, reject)
    }),
    changeEmailAddress: ({ state }, payload) => new Promise((resolve, reject) => {
      backend.changeEmailAddress({
        uuid: state.user.uuid,
        ...payload
      }, resolve, reject)
    }),
  },

  getters: {
    user: state => state.user,
    user_list: state => state.user_list,
    overview: state => state.overview,
  }

}