export default class Report
{
    constructor(report = {}) {
        this.id = report.id || null
        this.name = report.name || ''
        this.type = report.type || ''
        this.area = report.area || ''
        this.intro = report.intro || ''
        this.formats = report.formats || []
        this.meta = report.meta || {}
        this.all_customers = report.all_customers || true
        this.all_locations = report.all_locations || true
        this.customers = report.customers || []
        this.locations = report.locations || []
        this.equiment_classifications = report.equiment_classifications || []
        this.columns = report.columns || []
        this.checklists = report.checklists || []
        this.widgets = report.widgets || []
        this.range = report.range || ''
    }

    static mutations() {
        return {
            setReport: (state, payload) => state.report = new Report(payload),
            clearReport: (state) => state.report = new Report(),
            updateName: (state, payload) => state.report.name = payload,
            updateType: (state, payload) => state.report.type = payload,
            updateFormats: (state, payload) => state.report.formats = payload,
            updateArea: (state, payload) => state.report.area = payload,
            initMeta: (state, payload) => state.report.meta = payload,
            updateLocations: (state, payload) => state.report.locations = payload,
            updateCustomers: (state, payload) => state.report.customers = payload,
            toggleIncludeAllCustomers: (state) => state.report.all_customers = !state.report.all_customers,
            setIncludeAllCustomers: (state, payload) => state.report.all_customers = payload,
            toggleIncludeAllLocations: (state) => state.report.all_locations = !state.report.all_locations,
            setIncludeAllLocations: (state, payload) => state.report.all_locations = payload,
            // Meta Mutations
            toggleIncludeAllChecklists: (state) => state.report.meta.include_all_checklists = !state.report.meta.include_all_checklists,
            updateEquipmentClassifications: (state, payload) => state.report.equiment_classifications = payload,
            updateColumns: (state, payload) => state.report.columns = payload,
            updateIntro: (state, payload) => state.report.intro = payload,
            addWidget: (state, payload) => state.report.widgets.push(payload),
            updateRange: (state, payload) => state.report.range = payload,
            updateChecklists: (state, payload) => state.report.checklists = payload,
            removeWidget: (state, payload) => {
                const index = state.report.widgets.indexOf(payload)
                state.report.widgets.splice(index, 1)
            },
        }
    }

    static baseColumns() {
        return [
            { name: 'location', label: 'Location', type: 'relation', attribute: 'location.name' },
            { name: 'customer', label: 'Customer', type: 'relation', attribute: 'customer.name' },
        ]
    }
}