import axios from 'axios'
import store from '@/store'
import router from '@/router'
var Qs = require('qs')
import omitBy from 'lodash/omitBy'
import { services } from '@billow/js-helpers'

let baseURL = process.env.VUE_APP_BASE_URL

export let http = axios.create({
  baseURL,
  withCredentials: true,
})

http.interceptors.request.use(config => {
  Object.assign(config.headers, {
    'X-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
    ...services.auth.authHeaders()
  })
  return config
})

http.interceptors.response.use(response => {
  if (response.status === 200 || response.status === 201) store.commit('global/clearErrors')
  store.commit('global/setFrontendVersion', response.headers['x-fv'])
  return response
}, error => {
  if (error.response && error.response.status === 422) {
    store.commit('global/setErrors', error.response.data)
  }

  if (router.app && error.response && (error.response.status === 401 || error.response.status === 419)) {
    store.commit('auth/deleteToken')
    if (!['reset-password', 'forgot-password', 'signup'].includes(router.history.current.name)) {
      router.push({ name: 'signin' }).catch(() => (void 0))
    }
  }
  if (error.response && error.response.status === 403) {
    router.push({ name: 'dashboard' }).catch(() => (void 0))
  }

  return Promise.reject(error)
})

export let get = (url, success, failure, options = {}) => {
  return http.get(url, { 
      params: options.params,
      paramsSerializer: function (params) {
        let query = Qs.stringify(omitBy(params, value => {
          return value === '' || value === null
        }), {
          arrayFormat: 'brackets'
        })
        return query || ''
      },
    })
    .then(success)
    .catch(failure)
}

export let post = (url, data, success, failure) =>
  http.post(url, data)
    .then(success)
    .catch(failure)

export let put = (url, data, success, failure) =>
  http.put(url, data)
    .then(success)
    .catch(failure)

export let burn = (url, data, success, failure) =>
  http.delete(url, data)
    .then(success)
    .catch(failure)


export default {
  get,
  post,
  put,
  burn
}
