import { post, get, put, burn } from './request'

export default {
    create: (payload, success, failure) => post('/v1/equipment-classification', payload).then(success).catch(failure),
    update: (payload, success, failure) => put(`/v1/equipment-classifications/${payload.uuid}`, payload).then(success).catch(failure),
    loadList: (search, success, failure) => get(`/v1/equipment-classification-list`, success, failure, {
        params: {
            search
        }
    }),
    load: (payload, success, failure) => get(`/v1/equipment-classifications/${payload}`).then(success).catch(failure),
    loadOverview: (payload, success, failure) => get(`/v1/equipment-classifications/${payload}/overview`).then(success).catch(failure),
    duplicate: (payload, success, failure) => post(`/v1/equipment-classifications/${payload}/duplicate`).then(success).catch(failure),
    deleteClassification: (payload, success, failure) => burn(`/v1/equipment-classifications/${payload}`).then(success).catch(failure),
}